import Header from '../Header/Header'
import Main from '../Main/Main'
import Footer from '../Footer/Footer'

function HomePage() {
    return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
    );
  }
  
  export default HomePage;