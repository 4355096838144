

const MidMain = () => {
    return (
        <>
            <div>
                <div className='customImg' />
                <div className='top-headline'>Trending Cards</div>
            </div>
        </>
    )
}

export default MidMain;